import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import ReferralJoinForm from '../../components/common/referral-join-form'
import useSiteMetadata from '../../components/utilities/get-site-metadata'
import featuredImage from '../../images/hero/meati-referrals.jpg'

const ReferralJoinPage = () => {
  const { siteUrl } = useSiteMetadata()
  const imagePath = siteUrl + featuredImage

  const meta = [
    {
      name: 'og:image',
      content: imagePath,
    },
    {
      name: 'twitter:image',
      content: imagePath,
    },
    {
      name: 'og: title',
      content: 'Share and Win A Year of Meati™',
    },
    {
      name: 'og:description',
      content: 'We know you want Meati™ and we want to give it to you. Enter and share the love!',
    },
  ]

  return (
    <Layout>
      <Seo title="Refer a Friend'" />
      <Seo meta={meta} title="Share and Win A Year of Meati™" />
      <header id="referralHero" className="bg-warning">
        <Row className="align-items-start g-0">
          <Col md="6">
            <StaticImage
              src="../../images/hero/meati-referrals.jpg"
              quality={95}
              formats={['AUTO', 'WEBP']}
              alt="Meati™ Referrals"
              placeholder="blurred"
              layout="fullWidth"
              className="mb-md-3"
            />
          </Col>
          <Col md="6" className="align-self-center">
            <Container className="p-2 p-md-4">
              <h1 className="font-swish mb-1 text-primary">A Whole Year of Meati™ On Us</h1>
              <p>
                Think Your Friends Would Like Meati™? Of course they would! Bring them to the party! For
                every friend you refer you’ll receive an extra entry to win Meati™ for a year.
              </p>
              <ReferralJoinForm />
            </Container>
          </Col>
        </Row>
      </header>
    </Layout>
  )
}

export default ReferralJoinPage
